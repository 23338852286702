import get from "lodash/get";
import throttle from "lodash/throttle";
import PropTypes from "prop-types";
import React, { Component } from "react";

import { TIMINGS } from "@/constants";

import styles from "./StickyHeader.module.scss";

class moduleStickyHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fixed: false,
    };
    this.stickyHeaderRef = null;
    this.stickyHeaderInnerRef = null;
    this.handleScroll = this.handleScroll.bind(this);
    this.handleSpacer = this.handleSpacer.bind(this);
    this.throttledHandleScroll = throttle(
      this.handleScroll,
      TIMINGS.THROTTLE_SCROLL_TIME_OUT
    );
    this.throttledHandleResize = throttle(
      this.handleScroll,
      TIMINGS.THROTTLE_RESIZE_TIME_OUT
    );
    this.throttledHandleSpacer = throttle(
      this.handleSpacer,
      TIMINGS.THROTTLE_RESIZE_TIME_OUT
    );
  }
  componentDidMount() {
    this.handleSpacer();
    this.handleScroll();

    window.addEventListener("scroll", this.throttledHandleScroll);
    window.addEventListener("resize", this.throttledHandleResize);
    window.addEventListener("resize", this.throttledHandleSpacer);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.throttledHandleScroll);
    window.removeEventListener("resize", this.throttledHandleResize);
    window.removeEventListener("resize", this.throttledHandleSpacer);
  }

  handleSpacer() {
    if (!this.stickyHeaderRef || !this.stickyHeaderInnerRef || !window) {
      return;
    }
    const height = this.stickyHeaderInnerRef.offsetHeight;
    this.stickyHeaderRef.style.minHeight = `${height}px`;
  }
  handleScroll() {
    if (!this.stickyHeaderRef || !window) {
      return;
    }
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const headerDistanceFromTop = this.stickyHeaderRef.offsetTop;
    if (scrollTop > headerDistanceFromTop) {
      this.stickyHeaderInnerRef.classList.add(styles.headerInnerFixed);
      this.setState({ fixed: true });
    } else {
      this.stickyHeaderInnerRef.classList.remove(styles.headerInnerFixed);
      this.setState({ fixed: false });
    }
  }
  render() {
    const {
      data: { images, imagesMobile },
      isTransparent,
    } = this.props;
    const { fixed } = this.state;
    return (
      <section
        className={`${styles.stickyHeader}`}
        ref={(ele) => {
          this.stickyHeaderRef = ele;
        }}
      >
        <div
          className={`${styles.stickyHeaderInner} ${
            fixed ? styles.stickyHeaderInnerFixed : ""
          }`}
          ref={(ele) => {
            this.stickyHeaderInnerRef = ele;
          }}
          style={{ backgroundColor: isTransparent ? "none" : "black" }}
        >
          <div className="container">
            <div className={`${styles.stickyHeaderRow}`}>
              {images &&
                images.map((image, index) => {
                  const imageSrc = get(
                    image,
                    "fields.imageFile.fields.file.url",
                    false
                  );
                  const imageTitle = get(
                    image,
                    "fields.imageFile.fields.title",
                    false
                  );
                  return (
                    <div
                      className={`${styles.stickyHeaderRowItem}  d-xl-block d-lg-block d-md-nonde d-sm-none`}
                      key={index}
                    >
                      <img
                        alt={imageTitle}
                        draggable={false}
                        src={imageSrc}
                        title={imageTitle}
                      />
                    </div>
                  );
                })}
              {imagesMobile &&
                imagesMobile.map((image, index) => {
                  const imageSrc = get(
                    image,
                    "fields.imageFile.fields.file.url",
                    false
                  );
                  const imageTitle = get(
                    image,
                    "fields.imageFile.fields.title",
                    false
                  );
                  return (
                    <div
                      className={`${styles.stickyHeaderRowItem} d-xl-none d-lg-none d-md-block d-sm-block`}
                      key={index}
                    >
                      <img
                        alt={imageTitle}
                        draggable={false}
                        src={imageSrc}
                        title={imageTitle}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

moduleStickyHeader.propTypes = {
  data: PropTypes.object,
  isTransparent: PropTypes.bool,
};

export default moduleStickyHeader;
